import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Input, Domain, Country } from '../components/forms';

const PROVISION_TYPE = 'developer';
const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const PARTNER = '_sign_up_';
const UNKNOWN_ERROR_MESSAGE = 'An error occurred while attempting to sign up. Please try again later.';
const RE_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const VALIDATION_DEBOUNCE_TIME_MS = 1000;

// Each field has its own debounce timeout and they're stored here.
var validationTimeouts = {};

function cancelFieldValidation(fieldName) {
  if (Object.prototype.hasOwnProperty.call(validationTimeouts, fieldName)) {
    clearTimeout(validationTimeouts[fieldName]);
    delete validationTimeouts[fieldName];
  }
}

function cancelAllFieldValidations() {
  for (const fieldName in validationTimeouts) {
    cancelFieldValidation(fieldName);
  }
}

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      mobile: '',
      password: '',
      organization: '',
      subdomain: '',
      turnstileToken: '',
      agree_to_tos: false,
      agree_to_marketing: false,

      submittingRequest: false,
      generalErrors: [],
      fieldErrors: {}
    };
  }

  componentDidMount() {
    this.setUpTurnstile();
  }

  handleInputChange(event) {
    // Capture values in the closure
    const { name, value } = event.target;

    this.setState(previousState => ({
      ...previousState,
      [name]: value
    }), () => {
      cancelFieldValidation(name);

      validationTimeouts[name] = setTimeout(() => {
        this.validateField(name);
      }, VALIDATION_DEBOUNCE_TIME_MS);
    });
  }

  validateEmail() {
    const errors = [];

    if (!RE_EMAIL.test(this.state.email)) {
      errors.push('Not a valid email address');
    }

    return {
      'email': errors
    };
  }

  validateFirstName() {
    const errors = [];

    if (this.state.firstName.length < 1) {
      errors.push('This field is required');
    }

    return {
      'firstName': errors
    };
  }

  validateLastName() {
    const errors = [];

    if (this.state.lastName.length < 1) {
      errors.push('This field is required');
    }

    return {
      'lastName': errors
    };
  }

  validateSubdomain() {
    const errors = [];

    if (this.state.subdomain.length < 5 || this.state.subdomain.length > 36) {
      errors.push('Must be between 5 and 36 characters long');
    }

    return {
      'subdomain': errors
    };
  }

  validatePassword() {
    const errors = [];

    if (this.state.password.length < 10) {
      errors.push('Minimum 10 characters');
    }

    return {
      'password': errors
    };
  }

  validateOrganization() {
    const errors = [];

    if (this.state.organization.length < 1) {
      errors.push('This field is required');
    }

    return {
      'organization': errors
    };
  }

  validateField(fieldName) {
    let newErrors = null;

    switch (fieldName) {
      case 'firstName':
        newErrors = this.validateFirstName();
        break;

      case 'lastName':
        newErrors = this.validateLastName();
        break;

      case 'email':
        newErrors = this.validateEmail();
        break;

      case 'subdomain':
        newErrors = this.validateSubdomain();
        break;

      case 'password':
        newErrors = this.validatePassword();
        break;

      case 'organization':
        newErrors = this.validateOrganization();
        break;

      default:
        console.error('Unrecognized input name: ', fieldName);
        return;
    }

    const errors = { ...this.state.fieldErrors, ...newErrors };

    this.setState(previousState => ({
      ...previousState,
      fieldErrors: errors
    }));
  }

  hasAnyErrors(fieldErrors) {
    for (const key in fieldErrors) {
      if (!Object.prototype.hasOwnProperty.call(fieldErrors, key)) {
        continue;
      }

      if (fieldErrors[key].length > 0) {
        return false;
      }
    }

    return true;
  }

  validateAll() {
    cancelAllFieldValidations();

    const errors = {
      ...this.validateFirstName(),
      ...this.validateLastName(),
      ...this.validateEmail(),
      ...this.validateSubdomain(),
      ...this.validatePassword(),
      ...this.validateOrganization()
    };

    this.setState(previousState => ({
      ...previousState,
      fieldErrors: errors
    }));

    return this.hasAnyErrors(errors);
  }

  // The backend has different names for the fields. This maps field-level
  // errors from the backend to their names on the frontend.
  mapServerFieldNamesToClient(serverFieldErrors) {
    const clientFieldErrors = {};

    for (const serverFieldName in serverFieldErrors) {
      if (Object.prototype.hasOwnProperty.call(serverFieldErrors, serverFieldName)) {
        const clientFieldName = {
          'account_owner.name': 'fullName',
          'account_owner.email': 'email',
          'account_owner.phone': 'mobile',
          'account_owner.password': 'password',
          'name': 'organization',
          'subdomain': 'subdomain'
        }[serverFieldName];

        if (clientFieldName === undefined) {
          console.error('Unexpected clientFieldName: ', clientFieldName);
          continue;
        }

        clientFieldErrors[clientFieldName] = serverFieldErrors[serverFieldName];
      }
    }

    return clientFieldErrors;
  }

  handleSubmit() {
    // eslint-disable-next-line es/no-async-functions
    return async (event) => {
      event.preventDefault();

      if (!this.validateAll()) {
        console.log('validation failed, inhibiting submission');
        return;
      }

      this.setState(previousState => ({
        ...previousState,
        submittingRequest: true
      }));

      const subdomain = `dev-${this.state.subdomain}`;

      const requestBody = {
        provision_type: PROVISION_TYPE,
        account: {
          name: this.state.organization,
          subdomain: subdomain,
          time_zone: TIME_ZONE,
          partner: PARTNER,
          marketing_data: {},
          account_owner: {
            name: `${this.state.firstName} ${this.state.lastName}`,
            email: this.state.email,
            password: this.state.password,
            company: this.state.organization,
            country: this.state.country,
            agree_tos: this.state.agree_to_tos,
            agree_marketing: this.state.agree_to_marketing
          },
          phone_channel: {
            address: this.state.mobile,
            country_code: '+1',
            label: ''
          }
        }
      };

      let fetchOptions = {
        method: 'POST',
        body: JSON.stringify(requestBody),
        credentials: 'include'
      };

      let response;
      try {
        const url = `${process.env.GATSBY_SIGN_UP_BACKEND_URL}?cf-turnstile-response=${this.state.turnstileToken}`;
        response = await fetch(url, fetchOptions);
      } catch (error) {
        // TODO(Ian): What are the possible error conditions (app and http)? Add proper error handling

        this.setState(previousState => ({
          ...previousState,
          generalErrors: [UNKNOWN_ERROR_MESSAGE],
          fieldErrors: {},
          submittingRequest: false
        }));

        console.error('Network fetch error:', error);
        return;
      }

      switch (response.status) {
        case 201: {
          const entry_uri = response.headers.get('X-PagerDuty-New-Account-Entry-Uri');
          // This 500ms timeout taken from existing trial sign-up form.
          setTimeout(function () {
            window.location.replace(entry_uri);
          }, 500);

          break;
        }
        case 400: {
          // reset turnstile since the token could have been consumed by the backend
          window.turnstile.reset();

          // eslint-disable-next-line no-case-declarations
          const responseData = await response.json();

          // Errors from the backend can be in two different formats. One
          // format ties validation messages to specific fields (fieldErrors),
          // but the other does not (generalErrors).
          // eslint-disable-next-line no-prototype-builtins
          if (responseData.error.hasOwnProperty('code')) {
            this.setState(previousState => ({
              ...previousState,
              generalErrors: responseData.error.errors,
              fieldErrors: {},
              submittingRequest: false
            }));
          } else {
            this.setState(previousState => ({
              ...previousState,
              fieldErrors: this.mapServerFieldNamesToClient(responseData.error.errors),
              generalErrors: [],
              submittingRequest: false
            }));
          }

          break;
        }
        default: {
          this.setState(previousState => ({
            ...previousState,
            generalErrors: [UNKNOWN_ERROR_MESSAGE],
            fieldErrors: {},
            submittingRequest: false
          }));

          console.error('Unexpected status code: ', response.status);
          break;
        }
      }
    }
  }

  setUpTurnstile() {
    window.turnstileCallback = () => {
      window.turnstile.render('#turnstile-widget', {
        // For debugging
        // Sitekey                    Description          Visibility
        // 1x00000000000000000000AA   Always passes        visible
        // 2x00000000000000000000AB   Always blocks        visible
        // 1x00000000000000000000BB   Always passes        invisible
        // 2x00000000000000000000BB   Always blocks        invisible
        // 3x00000000000000000000FF   Forces interactive   visible
        // Note: this is just the widget results. The backend will always
        // require the real sitekey results.
        sitekey: process.env.GATSBY_CLOUDFLARE_TURNSTILE_SITEKEY,
        callback: (token) => {
          this.setState({ turnstileToken: token })
        }
      })
    }

    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=turnstileCallback';
    script.async = true;
    document.body.appendChild(script)
  }

  renderErrors(messages) {
    if (messages.length === 0) {
      return null;
    }

    return (
      <ul className="text-sm text-red">
        {messages.map((message, index) => (<li key={index}>{message}</li>))}
      </ul>
    );
  }

  renderFieldErrors(backendFieldName) {
    if (!Object.prototype.hasOwnProperty.call(this.state.fieldErrors, backendFieldName)) {
      return null;
    }

    const errors = this.state.fieldErrors[backendFieldName];
    return this.renderErrors(errors);
  }

  renderGeneralErrors() {
    return this.renderErrors(this.state.generalErrors);
  }

  render() {
    const onChange = this.handleInputChange.bind(this);
    const renderErrors = this.renderFieldErrors.bind(this);

    return (
      <Layout>
        <SEO title="Sign up" />
        <section className="sm:flex">
          <div className="text-white bg-blue-dark flex-container-1/2 py-2gut sm:py-3gut md:py-4gut lg:py-6gut">
            <div className="container-1/2 px-gut sm:ml-auto sm:pr-2gut md:pr-6gut lg:pr-8gut">
              <h1 className="font-extralight leading-none text-3xl md:text-4xl lg:text-5xl">
                Build with{" "}
                <em className="not-italic text-yellow">PagerDuty</em>
              </h1>
              <p className="leading-tight font-extralight tracking-loose text-yellow mt-2gut md:mt-4gut lg:mt-6gut
                            lg:text-lg">
                PagerDuty expands Digital{" "}Operations
              </p>
              <p className="mt-gut">
                Our 300+ technology and integration partners provide the
                integrations across a variety of cloud services, monitoring,
                deployment, and ticketing systems for enhanced incident
                resolution.
              </p>
            </div>
          </div>
          <div className="text-black bg-gray-light flex-container-1/2 py-2gut sm:py-3gut md:py-4gut lg:py-6gut">
            <div className="px-gut container-1/2 sm:mr-auto md:pl-3gut lg:pl-6gut">
              <h2 className="text-xl leading-2tight font-extralight">Developer account</h2>
              <p className="mt-gut">Get access to a free developer account limited to 3 users.</p>
              <p className="mt-gut mr-gut">Already have a PagerDuty account?{" "}
                <a href="https://app.pagerduty.com/" rel="noopener" className="text-green">Log In</a></p>
              <form className="flex flex-wrap mt-2gut" method="POST"
                id="submit-form"
                onSubmit={this.handleSubmit()}>
                <Input
                  id="First-name"
                  name="firstName"
                  value={this.state.firstName}
                  required={true}
                  autoFocus={true}
                  label="First name"
                  position="left"
                  onChange={onChange}
                  renderErrors={renderErrors}
                />
                <Input
                  id="Last-Name"
                  name="lastName"
                  value={this.state.lastName}
                  required={true}
                  label="Last name"
                  position="right"
                  onChange={onChange}
                  renderErrors={renderErrors}
                />

                <Input
                  id="Email"
                  name="email"
                  value={this.state.email}
                  required={true}
                  type="email"
                  label="Email"
                  onChange={onChange}
                  renderErrors={renderErrors}
                />

                <Country
                  id="country"
                  name="country"
                  required={true}
                  label="Country"
                  onChange={onChange}
                  renderErrors={renderErrors}
                />

                <Input
                  type="tel"
                  id="Mobile"
                  name="mobile"
                  value={this.state.mobile}
                  pattern="(.*[0-9]+.*)+"
                  label="Mobile Number (optional)"
                  position="left"
                  onChange={onChange}
                  renderErrors={renderErrors}
                />

                <Input
                  id="Organization"
                  name="organization"
                  value={this.state.organization}
                  required={true}
                  label="Organization"
                  position="right"
                  onChange={onChange}
                  renderErrors={renderErrors}
                />

                <Domain
                  id="Subdomain"
                  name="subdomain"
                  value={this.state.subdomain}
                  required={true}
                  prefix="https://dev-"
                  postfix=".pagerduty.com"
                  label="Subdomain"
                  minLength={5}
                  maxLength={36}
                  onChange={onChange}
                  renderErrors={renderErrors}
                />

                <Input
                  type="password"
                  id="Password"
                  name="password"
                  value={this.state.password}
                  required={true}
                  minLength={10}
                  label="Password"
                  onChange={onChange}
                  renderErrors={renderErrors}
                />

                <div className='flex items-start my-gut'>
                  <input
                    className="w-gut h-gut mr-gut flex-shrink-0"
                    type="checkbox"
                    id="agree-to-tos"
                    required={true}
                    onChange={e => this.setState({ agree_to_tos: e.target.checked })}
                  />

                  <label htmlFor="agree-to-tos" className="fine-print">
                    By checking this box and clicking the &quot;Submit&quot; button below, you acknowledge that you have read and reviewed the {" "}
                    <a className="text-green" rel="noreferrer" target="_blank" href="https://developer.pagerduty.com/docs/ZG9jOjExMDI5NTg0-pager-duty-developer-agreement">Developer Agreement</a>{" "}
                    and {" "}
                    <a className="text-green" rel="noreferrer" target="_blank" href="https://www.pagerduty.com/privacy-policy/">Privacy Policy</a> {" "}
                    and agree to be subject to those terms and policies.
                  </label>
                </div>

                <div className='flex items-start mb-gut'>
                  <input
                    className="w-gut h-gut mr-gut flex-shrink-0"
                    type="checkbox"
                    id="agree-to-marketing"
                    onChange={e => this.setState({ agree_to_marketing: e.target.checked })}
                  />

                  <label htmlFor="agree-to-marketing" className="fine-print">
                    I want to receive marketing communications from PagerDuty Marketing. I understand that I can opt out of marketing communications from
                    PagerDuty Marketing at any time by managing my <a href="https://info.pagerduty.com/2024-Preference-Center.html">marketing email preferences</a>.
                  </label>
                </div>

                <div id='turnstile-widget' />


                {this.renderGeneralErrors()}

                <input className="uppercase w-full text-white bg-green hover:bg-green-dark mt-gut px-gut
                                  flex justify-center items-center h-3gut"
                  value="Submit"
                  type="submit"
                  disabled={this.state.submittingRequest} />


                <p className="mt-gut fine-print">
                  <a href="https://developer.pagerduty.com/docs/ZG9jOjExMDI5NTY4-developer-account-faq" rel="noreferrer" target="_blank" className="text-green">Read Developer Account FAQ</a>
                </p >
              </form>
            </div>
          </div>
        </section>
        {/*
        <aside className="container mx-auto flex flex-wrap my-lane">
          <h2 className="px-gut text-lg leading-3tight font-extralight mb-2gut sm:mx-auto md:mx-0
                         md:mb-0 md:flex-initial md:w-3lane">
            Trusted by developers at top organizations
          </h2>
          <div className="p-gut flex items-center w-full sm:w-1/2 md:w-auto sm:justify-center md:mx-auto">
            <img style={{width:"8rem"}} src={LogoGap}/>
          </div>
          <div className="p-gut flex items-center w-full sm:w-1/2 md:w-auto sm:justify-center md:mx-auto">
            <img style={{width:"10rem"}} src={LogoPanasonic}/>
          </div>
          <div className="p-gut flex items-center w-full sm:w-1/2 md:w-auto sm:justify-center md:mx-auto">
            <img style={{width:"6rem"}} src={LogoXero}/>
          </div>
          <div className="p-gut flex items-center w-full sm:w-1/2 md:w-auto sm:justify-center md:ml-auto">
            <img style={{width:"12rem"}} src={LogoTheTelegraph}/>
          </div>
        </aside>
         */}
      </Layout>
    );
  }
}
