import React from 'react';

function noop() { }

export class Input extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { id, name, value, label } = this.props;
    const { autofocus, required, pattern, minLength, maxLength } = this.props;
    const type = this.props.type || 'text';
    const onChange = typeof this.props.onChange === 'function' ? this.props.onChange : noop;
    const renderErrors = typeof this.props.renderErrors === 'function' ? this.props.renderErrors : noop;
    const position = this.props.position;
    let className = 'flex flex-col pb-gut w-full';
    if (position === 'left') className += ' md:pr-gut md:w-1/2';
    else if (position === 'right') className += ' md:pl-gut md:w-1/2';
    return (
      <div className={className}>
        <input className="input bg-none h-2gut border-b border-gray"
          type={type}
          placeholder=" "
          id={id}
          name={name}
          value={value}
          required={Boolean(required)}
          {...(pattern ? { pattern } : null)}
          {...(parseInt(minLength, 10) ? { minLength } : null)}
          {...(parseInt(maxLength, 10) ? { maxLength } : null)}
          autoFocus={Boolean(autofocus)}
          onChange={onChange} />
        <label className="ph-label text-blue-dark"
          htmlFor={id}>{label}</label>
        {renderErrors(name)}
      </div>
    );
  }
}

export class Domain extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { id, name, value, prefix, postfix, label } = this.props;
    const { autofocus, required, pattern, minLength, maxLength } = this.props;
    const onChange = typeof this.props.onChange === 'function' ? this.props.onChange : noop;
    const renderErrors = typeof this.props.renderErrors === 'function' ? this.props.renderErrors : noop;
    return (
      <div className="flex flex-col w-full">
        <div className="flex items-end">
          <span className="text-sm tracking-none text-blue-dark mb-gut pb-5
                          border-b border-gray">{prefix}</span>
          <div className="flex-1 flex flex-col pb-gut">
            <input className="input bg-none h-2gut border-b border-gray"
              type="text"
              size={1}
              placeholder=" "
              id={id}
              name={name}
              value={value}
              autoComplete="off"
              required={Boolean(required)}
              {...(pattern ? { pattern } : null)}
              {...(parseInt(minLength, 10) ? { minLength } : null)}
              {...(parseInt(maxLength, 10) ? { maxLength } : null)}
              autoFocus={Boolean(autofocus)}
              onChange={onChange} />
            <label className="ph-label text-blue-dark"
              htmlFor={id}>{label}</label>
          </div>
          <span className="text-sm tracking-none text-blue-dark mb-gut pb-5
                           border-b border-gray">{postfix}</span>
        </div>
        <div className="-mt-gut mb-gut">{renderErrors(name)}</div>
      </div>
    );
  }
}

export class Country extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { id, name, required, label, countries, selected } = this.props;
    const onChange = typeof this.props.onChange === 'function' ? this.props.onChange : noop;
    const renderErrors = typeof this.props.renderErrors === 'function' ? this.props.renderErrors : noop;
    return (
      <div className="flex flex-col pb-gut w-full">
        <select
          className="select bg-none h-2gut border-b border-gray appearance-none"
          id={id}
          name={name}
          required={Boolean(required)}
          onChange={onChange}>
          <option value="" disabled selected>Select a country</option>
          {(countries || Country.defaultCountries).map(({ code, name }) => <option value={code} key={code}>{name}</option>)}
        </select>
        <label className="ph-label text-blue-dark"
          htmlFor={id}>{label}</label>
        {renderErrors(name)}
      </div>
    );
  }

  static defaultCountries = [
    {
      "name": "United States",
      "code": "United States"
    },
    {
      "name": "Canada",
      "code": "Canada"
    },
    {
      "name": "Afghanistan",
      "code": "Afghanistan"
    },
    {
      "name": "Aland Islands",
      "code": "Aland Islands"
    },
    {
      "name": "Albania",
      "code": "Albania"
    },
    {
      "name": "Algeria",
      "code": "Algeria"
    },
    {
      "name": "American Samoa",
      "code": "American Samoa"
    },
    {
      "name": "Andorra",
      "code": "Andorra"
    },
    {
      "name": "Angola",
      "code": "Angola"
    },
    {
      "name": "Anguilla",
      "code": "Anguilla"
    },
    {
      "name": "Antarctica",
      "code": "Antarctica"
    },
    {
      "name": "Antigua and Barbuda",
      "code": "Antigua and Barbuda"
    },
    {
      "name": "Argentina",
      "code": "Argentina"
    },
    {
      "name": "Armenia",
      "code": "Armenia"
    },
    {
      "name": "Aruba",
      "code": "Aruba"
    },
    {
      "name": "Australia",
      "code": "Australia"
    },
    {
      "name": "Austria",
      "code": "Austria"
    },
    {
      "name": "Azerbaijan",
      "code": "Azerbaijan"
    },
    {
      "name": "Bahamas",
      "code": "Bahamas"
    },
    {
      "name": "Bahrain",
      "code": "Bahrain"
    },
    {
      "name": "Bangladesh",
      "code": "Bangladesh"
    },
    {
      "name": "Barbados",
      "code": "Barbados"
    },
    {
      "name": "Belarus",
      "code": "Belarus"
    },
    {
      "name": "Belgium",
      "code": "Belgium"
    },
    {
      "name": "Belize",
      "code": "Belize"
    },
    {
      "name": "Benin",
      "code": "Benin"
    },
    {
      "name": "Bermuda",
      "code": "Bermuda"
    },
    {
      "name": "Bhutan",
      "code": "Bhutan"
    },
    {
      "name": "Bolivia",
      "code": "Bolivia, Plurinational State of"
    },
    {
      "name": "Bosnia and Herzegovina",
      "code": "Bosnia and Herzegovina"
    },
    {
      "name": "Botswana",
      "code": "Botswana"
    },
    {
      "name": "Bouvet Island",
      "code": "Bouvet Island"
    },
    {
      "name": "Brazil",
      "code": "Brazil"
    },
    {
      "name": "British Virgin Islands",
      "code": "Virgin Islands, British"
    },
    {
      "name": "British Indian Ocean Territory (Chagos Archipelago)",
      "code": "British Indian Ocean Territory"
    },
    {
      "name": "Brunei Darussalam",
      "code": "Brunei Darussalam"
    },
    {
      "name": "Bulgaria",
      "code": "Bulgaria"
    },
    {
      "name": "Burkina Faso",
      "code": "Burkina Faso"
    },
    {
      "name": "Burundi",
      "code": "Burundi"
    },
    {
      "name": "Cambodia",
      "code": "Cambodia"
    },
    {
      "name": "Cameroon",
      "code": "Cameroon"
    },
    {
      "name": "Cape Verde",
      "code": "Cape Verde"
    },
    {
      "name": "Cayman Islands",
      "code": "Cayman Islands"
    },
    {
      "name": "Central African Republic",
      "code": "Central African Republic"
    },
    {
      "name": "Chad",
      "code": "Chad"
    },
    {
      "name": "Chile",
      "code": "Chile"
    },
    {
      "name": "China",
      "code": "China"
    },
    {
      "name": "Hong Kong",
      "code": "Hong Kong"
    },
    {
      "name": "Macao",
      "code": "Macao"
    },
    {
      "name": "Christmas Island",
      "code": "Christmas Island"
    },
    {
      "name": "Cocos Islands",
      "code": "Cocos (Keeling) Islands"
    },
    {
      "name": "Colombia",
      "code": "Colombia"
    },
    {
      "name": "Comoros",
      "code": "Comoros"
    },
    {
      "name": "Congo, Republic of",
      "code": "Congo"
    },
    {
      "name": "Congo, Democratic Republic of",
      "code": "Congo, the Democratic Republic of the"
    },
    {
      "name": "Cook Islands",
      "code": "Cook Islands"
    },
    {
      "name": "Costa Rica",
      "code": "Costa Rica"
    },
    {
      "name": "Ivory Coast",
      "code": "Cote d'Ivoire"
    },
    {
      "name": "Croatia",
      "code": "Croatia"
    },
    {
      "name": "Cyprus",
      "code": "Cyprus"
    },
    {
      "name": "Czech Republic",
      "code": "Czech Republic"
    },
    {
      "name": "Denmark",
      "code": "Denmark"
    },
    {
      "name": "Djibouti",
      "code": "Djibouti"
    },
    {
      "name": "Dominica",
      "code": "Dominica"
    },
    {
      "name": "Dominican Republic",
      "code": "Dominican Republic"
    },
    {
      "name": "Ecuador",
      "code": "Ecuador"
    },
    {
      "name": "Egypt",
      "code": "Egypt"
    },
    {
      "name": "El Salvador",
      "code": "El Salvador"
    },
    {
      "name": "Equatorial Guinea",
      "code": "Equatorial Guinea"
    },
    {
      "name": "Eritrea",
      "code": "Eritrea"
    },
    {
      "name": "Estonia",
      "code": "Estonia"
    },
    {
      "name": "Ethiopia",
      "code": "Ethiopia"
    },
    {
      "name": "Falkland Islands",
      "code": "Falkland Islands (Malvinas)"
    },
    {
      "name": "Faeroe Islands",
      "code": "Faroe Islands"
    },
    {
      "name": "Fiji",
      "code": "Fiji"
    },
    {
      "name": "Finland",
      "code": "Finland"
    },
    {
      "name": "France",
      "code": "France"
    },
    {
      "name": "French Guiana",
      "code": "French Guiana"
    },
    {
      "name": "French Polynesia",
      "code": "French Polynesia"
    },
    {
      "name": "French Southern Territories",
      "code": "French Southern Territories"
    },
    {
      "name": "Gabon",
      "code": "Gabon"
    },
    {
      "name": "Gambia",
      "code": "Gambia"
    },
    {
      "name": "Georgia",
      "code": "Georgia"
    },
    {
      "name": "Germany",
      "code": "Germany"
    },
    {
      "name": "Ghana",
      "code": "Ghana"
    },
    {
      "name": "Gibraltar",
      "code": "Gibraltar"
    },
    {
      "name": "Greece",
      "code": "Greece"
    },
    {
      "name": "Greenland",
      "code": "Greenland"
    },
    {
      "name": "Grenada",
      "code": "Grenada"
    },
    {
      "name": "Guadaloupe",
      "code": "Guadeloupe"
    },
    {
      "name": "Guam",
      "code": "Guam"
    },
    {
      "name": "Guatemala",
      "code": "Guatemala"
    },
    {
      "name": "Guernsey",
      "code": "Guernsey"
    },
    {
      "name": "Guinea",
      "code": "Guinea"
    },
    {
      "name": "Guinea-Bissau",
      "code": "Guinea-Bissau"
    },
    {
      "name": "Guyana",
      "code": "Guyana"
    },
    {
      "name": "Haiti",
      "code": "Haiti"
    },
    {
      "name": "Heard and McDonald Islands",
      "code": "Heard Island and Mcdonald Islands"
    },
    {
      "name": "Vatican City",
      "code": "Holy See (Vatican City State)"
    },
    {
      "name": "Honduras",
      "code": "Honduras"
    },
    {
      "name": "Hungary",
      "code": "Hungary"
    },
    {
      "name": "Iceland",
      "code": "Iceland"
    },
    {
      "name": "India",
      "code": "India"
    },
    {
      "name": "Indonesia",
      "code": "Indonesia"
    },
    {
      "name": "Iraq",
      "code": "Iraq"
    },
    {
      "name": "Ireland",
      "code": "Ireland"
    },
    {
      "name": "Isle of Man",
      "code": "Isle of Man"
    },
    {
      "name": "Israel",
      "code": "Israel"
    },
    {
      "name": "Italy",
      "code": "Italy"
    },
    {
      "name": "Jamaica",
      "code": "Jamaica"
    },
    {
      "name": "Japan",
      "code": "Japan"
    },
    {
      "name": "Jersey",
      "code": "Jersey"
    },
    {
      "name": "Jordan",
      "code": "Jordan"
    },
    {
      "name": "Kazakhstan",
      "code": "Kazakhstan"
    },
    {
      "name": "Kenya",
      "code": "Kenya"
    },
    {
      "name": "Kiribati",
      "code": "Kiribati"
    },
    {
      "name": "South Korea",
      "code": "Republic of Korea"
    },
    {
      "name": "Kuwait",
      "code": "Kuwait"
    },
    {
      "name": "Kyrgyz Republic",
      "code": "Kyrgyzstan"
    },
    {
      "name": "Laos",
      "code": "Lao People's Democratic Republic"
    },
    {
      "name": "Latvia",
      "code": "Latvia"
    },
    {
      "name": "Lebanon",
      "code": "Lebanon"
    },
    {
      "name": "Lesotho",
      "code": "Lesotho"
    },
    {
      "name": "Liberia",
      "code": "Liberia"
    },
    {
      "name": "Libyan Arab Jamahiriya",
      "code": "Libyan Arab Jamahiriya"
    },
    {
      "name": "Liechtenstein",
      "code": "Liechtenstein"
    },
    {
      "name": "Lithuania",
      "code": "Lithuania"
    },
    {
      "name": "Luxembourg",
      "code": "Luxembourg"
    },
    {
      "name": "Macedonia",
      "code": "Macedonia, the former Yugoslav Republic of"
    },
    {
      "name": "Madagascar",
      "code": "Madagascar"
    },
    {
      "name": "Malawi",
      "code": "Malawi"
    },
    {
      "name": "Malaysia",
      "code": "Malaysia"
    },
    {
      "name": "Maldives",
      "code": "Maldives"
    },
    {
      "name": "Mali",
      "code": "Mali"
    },
    {
      "name": "Malta",
      "code": "Malta"
    },
    {
      "name": "Marshall Islands",
      "code": "Marshall Islands"
    },
    {
      "name": "Martinique",
      "code": "Martinique"
    },
    {
      "name": "Mauritania",
      "code": "Mauritania"
    },
    {
      "name": "Mauritius",
      "code": "Mauritius"
    },
    {
      "name": "Mayotte",
      "code": "Mayotte"
    },
    {
      "name": "Mexico",
      "code": "Mexico"
    },
    {
      "name": "Micronesia",
      "code": "Micronesia"
    },
    {
      "name": "Moldova",
      "code": "Moldova, Republic of"
    },
    {
      "name": "Monaco",
      "code": "Monaco"
    },
    {
      "name": "Mongolia",
      "code": "Mongolia"
    },
    {
      "name": "Montenegro",
      "code": "Montenegro"
    },
    {
      "name": "Montserrat",
      "code": "Montserrat"
    },
    {
      "name": "Morocco",
      "code": "Morocco"
    },
    {
      "name": "Mozambique",
      "code": "Mozambique"
    },
    {
      "name": "Myanmar",
      "code": "Myanmar"
    },
    {
      "name": "Namibia",
      "code": "Namibia"
    },
    {
      "name": "Nauru",
      "code": "Nauru"
    },
    {
      "name": "Nepal",
      "code": "Nepal"
    },
    {
      "name": "Netherlands",
      "code": "Netherlands"
    },
    {
      "name": "Netherlands Antilles",
      "code": "Netherlands Antilles"
    },
    {
      "name": "New Caledonia",
      "code": "New Caledonia"
    },
    {
      "name": "New Zealand",
      "code": "New Zealand"
    },
    {
      "name": "Nicaragua",
      "code": "Nicaragua"
    },
    {
      "name": "Niger",
      "code": "Niger"
    },
    {
      "name": "Nigeria",
      "code": "Nigeria"
    },
    {
      "name": "Niue",
      "code": "Niue"
    },
    {
      "name": "Norfolk Island",
      "code": "Norfolk Island"
    },
    {
      "name": "Northern Mariana Islands",
      "code": "Northern Mariana Islands"
    },
    {
      "name": "Norway",
      "code": "Norway"
    },
    {
      "name": "Oman",
      "code": "Oman"
    },
    {
      "name": "Pakistan",
      "code": "Pakistan"
    },
    {
      "name": "Palau",
      "code": "Palau"
    },
    {
      "name": "Palestinian Territory",
      "code": "Palestinian Territory, Occupied"
    },
    {
      "name": "Panama",
      "code": "Panama"
    },
    {
      "name": "Papua New Guinea",
      "code": "Papua New Guinea"
    },
    {
      "name": "Paraguay",
      "code": "Paraguay"
    },
    {
      "name": "Peru",
      "code": "Peru"
    },
    {
      "name": "Philippines",
      "code": "Philippines"
    },
    {
      "name": "Pitcairn Island",
      "code": "Pitcairn"
    },
    {
      "name": "Poland",
      "code": "Poland"
    },
    {
      "name": "Portugal",
      "code": "Portugal"
    },
    {
      "name": "Puerto Rico",
      "code": "Puerto Rico"
    },
    {
      "name": "Qatar",
      "code": "Qatar"
    },
    {
      "name": "Reunion",
      "code": "Reunion"
    },
    {
      "name": "Romania",
      "code": "Romania"
    },
    {
      "name": "Russia",
      "code": "Russian Federation"
    },
    {
      "name": "Rwanda",
      "code": "Rwanda"
    },
    {
      "name": "Saint Barthélemy",
      "code": "Saint Barthélemy"
    },
    {
      "name": "St. Helena",
      "code": "Saint Helena, Ascension and Tristan da Cunha"
    },
    {
      "name": "St. Kitts and Nevis",
      "code": "Saint Kitts and Nevis"
    },
    {
      "name": "St. Lucia",
      "code": "Saint Lucia"
    },
    {
      "name": "Saint Martin (French part)",
      "code": "Saint Martin (French part)"
    },
    {
      "name": "St. Pierre and Miquelon",
      "code": "Saint Pierre and Miquelon"
    },
    {
      "name": "St. Vincent and the Grenadines",
      "code": "Saint Vincent and the Grenadines"
    },
    {
      "name": "Samoa",
      "code": "Samoa"
    },
    {
      "name": "San Marino",
      "code": "San Marino"
    },
    {
      "name": "Sao Tome and Principe",
      "code": "Sao Tome and Principe"
    },
    {
      "name": "Saudi Arabia",
      "code": "Saudi Arabia"
    },
    {
      "name": "Senegal",
      "code": "Senegal"
    },
    {
      "name": "Serbia",
      "code": "Serbia"
    },
    {
      "name": "Seychelles",
      "code": "Seychelles"
    },
    {
      "name": "Sierra Leone",
      "code": "Sierra Leone"
    },
    {
      "name": "Singapore",
      "code": "Singapore"
    },
    {
      "name": "Slovakia",
      "code": "Slovakia"
    },
    {
      "name": "Slovenia",
      "code": "Slovenia"
    },
    {
      "name": "Solomon Islands",
      "code": "Solomon Islands"
    },
    {
      "name": "Somalia",
      "code": "Somalia"
    },
    {
      "name": "South Africa",
      "code": "South Africa"
    },
    {
      "name": "South Georgia and the South Sandwich Islands",
      "code": "South Georgia and the South Sandwich Islands"
    },
    {
      "name": "Spain",
      "code": "Spain"
    },
    {
      "name": "Sri Lanka",
      "code": "Sri Lanka"
    },
    {
      "name": "Suriname",
      "code": "Suriname"
    },
    {
      "name": "Svalbard & Jan Mayen Islands",
      "code": "Svalbard and Jan Mayen"
    },
    {
      "name": "Swaziland",
      "code": "Swaziland"
    },
    {
      "name": "Sweden",
      "code": "Sweden"
    },
    {
      "name": "Switzerland",
      "code": "Switzerland"
    },
    {
      "name": "Taiwan",
      "code": "Taiwan"
    },
    {
      "name": "Tajikistan",
      "code": "Tajikistan"
    },
    {
      "name": "Tanzania",
      "code": "Tanzania, United Republic of"
    },
    {
      "name": "Thailand",
      "code": "Thailand"
    },
    {
      "name": "Timor-Leste",
      "code": "Timor-Leste"
    },
    {
      "name": "Togo",
      "code": "Togo"
    },
    {
      "name": "Tokelau",
      "code": "Tokelau"
    },
    {
      "name": "Tonga",
      "code": "Tonga"
    },
    {
      "name": "Trinidad and Tobago",
      "code": "Trinidad and Tobago"
    },
    {
      "name": "Tunisia",
      "code": "Tunisia"
    },
    {
      "name": "Turkey",
      "code": "Turkey"
    },
    {
      "name": "Turkmenistan",
      "code": "Turkmenistan"
    },
    {
      "name": "Turks and Caicos Islands",
      "code": "Turks and Caicos Islands"
    },
    {
      "name": "Tuvalu",
      "code": "Tuvalu"
    },
    {
      "name": "Uganda",
      "code": "Uganda"
    },
    {
      "name": "Ukraine",
      "code": "Ukraine"
    },
    {
      "name": "United Arab Emirates",
      "code": "United Arab Emirates"
    },
    {
      "name": "United Kingdom",
      "code": "United Kingdom"
    },
    {
      "name": "United States Minor Outlying Islands",
      "code": "United States Minor Outlying Islands"
    },
    {
      "name": "Uruguay",
      "code": "Uruguay"
    },
    {
      "name": "Uzbekistan",
      "code": "Uzbekistan"
    },
    {
      "name": "Vanuatu",
      "code": "Vanuatu"
    },
    {
      "name": "Venezuela",
      "code": "Venezuela, Bolivarian Republic of"
    },
    {
      "name": "Vietnam",
      "code": "Vietnam"
    },
    {
      "name": "US Virgin Islands",
      "code": "Virgin Islands, US"
    },
    {
      "name": "Wallis and Futuna Islands",
      "code": "Wallis and Futuna"
    },
    {
      "name": "Western Sahara",
      "code": "Western Sahara"
    },
    {
      "name": "Yemen",
      "code": "Yemen"
    },
    {
      "name": "Zambia",
      "code": "Zambia"
    },
    {
      "name": "Zimbabwe",
      "code": "Zimbabwe"
    }
  ]
}
